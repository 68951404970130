import {
  ChromeOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import {
  Button,
  Collapse,
  Form,
  Input,
  InputNumber,
  Modal,
  Popconfirm,
  Switch,
  Table,
  notification,
} from 'antd';
import { t } from 'i18n';
import { getCookie } from 'pkg/cookie/helpers';
import { promotionClient } from 'pkg/request/clients';
import React, { useEffect, useRef, useState } from 'react';
import '../Blacklist/Blacklist.scss';
import AddBlacklistNew from './AddBlacklistNew';
import ApplyBlacklistNew from './ApplyBlacklistNew';
import { isMobile } from 'react-device-detect';

let originData: any[] = [];

function randomIntFromInterval(min: number, max: number) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

const randomListWebsite = () => {
  let numberRamdom = randomIntFromInterval(0, 3);
  const arr = [
    `https://baophapluat.vn/`,
    'https://facebook.com/',
    'https://zalo.com/',
    'https://safeweb.app/',
  ];
  return arr[numberRamdom];
};

for (let i = 0; i < 3; i++) {
  originData.push({
    key: i.toString(),
    name: randomListWebsite(),
  });
}

export default function BlacklistNew(props: any) {
  const [dataSource, setDataSoucre] = useState([]);
  const [loadingStaffActive, setLoadingStaffActive] = useState(false);
  const isEditing = (record: { key: any }) => record.key === editingKey;
  const [dataApply, setDataApply] = useState([] as any[]);

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };

  const tailLayout = {
    wrapperCol: { offset: 0, span: 16 },
  };

  const [form] = Form.useForm();

  useEffect(() => {
    getApi();
    getListStaffOfAdminId();
    getBlacklistApiOfStaff();
  }, []);

  const [data, setData] = useState([]);

  const getBlacklist = () => {
    promotionClient
      .post('/web_rule/blacklist/admin/list', {
        admin_id: getAdminId(),
      })
      .then((res: any) => {
        if (res.data.total) {
          setData(res.data.rules);
        } else {
          notification.error({
            message: 'Error',
            description: res.data.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err,
        });
      });
  };

  const AddBlacklistGroup = (values: any) => {
    promotionClient
      .post('/web_rule/blacklist/admin/create', {
        admin_id: getAdminId(),
        name: values.name,
        list: [],
      })
      .then((res: any) => {
        if (res.data.success) {
          notification.success({
            message: t('Success'),
            description: t('notification.create.success', {
              type: t('blacklistType'),
            }),
          });
          getApi();
        } else {
          notification.error({
            message: 'Error',
            description: res.data.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err || 'error',
        });
      });
  };
  const getApi = () => {
    promotionClient
      .post('/web_rule/blacklist/admin/list', {
        admin_id: getAdminId(),
      })
      .then((res: any) => {
        if (res.data.total) {
          setData(res.data.rules);
        } else {
          notification.error({
            message: 'Error',
            description: res?.data?.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err,
        });
      });
  };
  const getListStaffOfAdminId = () => {
    promotionClient
      .post('/staff_user/admin/list', {
        admin_id: getAdminId(),
        token: '',
      })
      .then((res) => {
        setDataSoucre(res.data.staff_list || []);
      });
  };

  const getAdminId = () => {
    return getCookie('current_username');
  };

  const onReset = () => {
    form.resetFields();
  };

  const EditableCell = (props: any) => {
    const inputNode =
      props.inputType === 'number' ? <InputNumber /> : <Input />;
    return (
      <td {...props}>
        {props.editing ? (
          <Form.Item
            name={props.dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `${t('notification.input.please', {
                  name: props.title,
                })}`,
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          props.children
        )}
      </td>
    );
  };
  const columnsApplyPC = [
    {
      title: `${t('userManagement.blackList.createWebsite.id')}`,
      dataIndex: 'id',
      key: 'id',
      width: 45,
      render: (value: any, staff: any, index: any) => <span>{index + 1}</span>,
    },
    {
      title: `${t('userManagement.blackList.add.list.name')}`,
      dataIndex: 'full_name',
      width: '10%',
      editable: true,
    },
    {
      title: `${t('userManagement.blackList.add.list.Blacklist')}`,
      dataIndex: 'blacklist_name',
      editable: true,
    },
    {
      title: `${t('userManagement.blackList.add.list.runMode')}`,
      dataIndex: 'enable',
      key: 'enable',
      render: (value: any, staff: any, index: number) => {
        return (
          // dung onchange
          <form>
            <div className="radio-group">
              <label>
                <input
                  style={{
                    width: '20px',
                    height: '20px',
                    position: 'relative',
                    top: '5px',
                  }}
                  onChange={() =>
                    handleOptionChange('normal', value, staff, index)
                  }
                  type="radio"
                  id="default"
                  name="normal"
                  checked={staff.run_mode === 'normal'}
                />
                <span style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                  {t('userManagement.blackList.add.list.normal')}
                </span>
              </label>
              <label>
                <input
                  style={{
                    width: '20px',
                    height: '20px',
                    position: 'relative',
                    top: '5px',
                  }}
                  onChange={() =>
                    handleOptionChange('blacklist', value, staff, index)
                  }
                  type="radio"
                  id="blacklist"
                  name="blacklist"
                  checked={staff.run_mode === 'blacklist'}
                />
                <span style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                  {t('userManagement.blackList.add.list.blockWeb')}
                </span>
              </label>
            </div>
          </form>
        );
      },
    },
    {
      title: `${t('userManagement.blackList.add.list.tabMode')}`,
      key: 'active',
      render: (value: any, staff: any) => (
        <Switch
          onChange={() => handleActiveStaff(value, staff)}
          defaultChecked={staff.tab_action === 'closetab'}
        />
      ),
    },
  ];
  const columnsApplySP = [
    {
      title: `${t('userManagement.blackList.createWebsite.id')}`,
      dataIndex: 'id',
      key: 'id',
      width: 45,
      render: (value: any, staff: any, index: any) => <span>{index + 1}</span>,
    },
    {
      title: `${t('userManagement.blackList.add.list.name')}`,
      dataIndex: 'full_name',
      width: '10%',
      editable: true,
    },
    {
      title: `${t('userManagement.blackList.add.list.Blacklist')}`,
      dataIndex: 'enable',
      key: 'enable',
      render: (value: any, staff: any, index: number) => {
        return (
          // dung onchange
          <form>
            <div style={{ marginBottom: 10 }}>{staff.blacklist_name}</div>
            <div className="radio-group">
              <label>
                <input
                  style={{
                    width: '20px',
                    height: '20px',
                    position: 'relative',
                    top: '5px',
                  }}
                  onChange={() =>
                    handleOptionChange('normal', value, staff, index)
                  }
                  type="radio"
                  id="default"
                  name="normal"
                  checked={staff.run_mode === 'normal'}
                />
                <span style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                  {t('userManagement.blackList.add.list.normal')}
                </span>
              </label>
              <label>
                <input
                  style={{
                    width: '20px',
                    height: '20px',
                    position: 'relative',
                    top: '5px',
                  }}
                  onChange={() =>
                    handleOptionChange('blacklist', value, staff, index)
                  }
                  type="radio"
                  id="blacklist"
                  name="blacklist"
                  checked={staff.run_mode === 'blacklist'}
                />
                <span style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                  {t('userManagement.blackList.add.list.blockWeb')}
                </span>
              </label>
            </div>
          </form>
        );
      },
    },
    {
      title: `${t('userManagement.blackList.add.list.tabMode')}`,
      key: 'active',
      render: (value: any, staff: any) => (
        <Switch
          onChange={() => handleActiveStaff(value, staff)}
          defaultChecked={staff.tab_action === 'closetab'}
        />
      ),
    },
  ];

  const handleOptionChange = (
    status: any,
    value: any,
    staff: any,
    index: number
  ) => {
    let vType = 'normal';

    switch (status) {
      case 'blacklist':
        vType = 'blacklist';
        break;

      case 'whitelist':
        vType = 'whitelist';
        break;

      default:
        vType = 'normal';
        break;
    }
    promotionClient
      .post(`staff_web/${vType}/admin/set`, {
        staff_id: staff.staff_id,
        mode: vType,
      })
      .then((res) => {
        if (res.data.success) {
          notification.success({
            message: t('Success'),
            description: t('notification.tabMode.update.success', {
              type: vType !== 'blacklist' ? t(`${vType}`) : t('blacklistType'),
            }),
          });
          getBlacklistApiOfStaff();
        } else {
          notification.error({
            message: 'Error',
            description: res.data.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err,
        });
      });
  };

  const handleActiveStaff = (value: any, myStaff: any) => {
    if (myStaff.tab_action === 'cover') {
      setLoadingStaffActive(true);
    } else {
      setLoadingStaffActive(false);
    }
    promotionClient
      .post('/staff_web/tab_mode/admin/set', {
        staff_id: myStaff.staff_id,
        mode: myStaff.tab_action === 'cover' ? 'closetab' : 'cover',
      })
      .then((res: any) => {
        if (res.data.success) {
          getBlacklistApiOfStaff();
          notification.success({
            message: t('Success'),
            description: loadingStaffActive
              ? t('notification.activeStaff.success')
              : t('notification.InactiveStaff.success'),
          });
        } else {
          notification.error({
            message: t('Error'),
            description: t('notification.activeStaff.fail'),
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err || 'error',
        });
      });
  };
  const mergedColumns = isMobile
    ? columnsApplySP.map((col, index) => {
        if (!col.editable) {
          return {
            index: index,
            ...col,
          };
        }

        return {
          index: index,
          ...col,
          onCell: (record: any) => ({
            record,
            inputType: col.dataIndex === 'age' ? 'number' : 'text',
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record),
          }),
        };
      })
    : columnsApplyPC.map((col, index) => {
        if (!col.editable) {
          return {
            index: index,
            ...col,
          };
        }

        return {
          index: index,
          ...col,
          onCell: (record: any) => ({
            record,
            inputType: col.dataIndex === 'age' ? 'number' : 'text',
            dataIndex: col.dataIndex,
            title: col.title,
            editing: isEditing(record),
          }),
        };
      });

  const getBlacklistApiOfStaff = () => {
    promotionClient
      .post('staff_web/admin/list', {
        admin_id: getAdminId(),
      })
      .then((res: any) => {
        if (res.data.total) {
          if (res?.data?.staff_web?.length) {
            const dataTable = res.data.staff_web.map(
              (val: any, index: number) => {
                return {
                  index: index,
                  ...val,
                };
              }
            );
            setDataApply(dataTable);
          } else {
            setDataApply([]);
          }
        } else {
          notification.error({
            message: 'Error',
            description: res.data.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err,
        });
      });
  };
  const [editingKey, setEditingKey] = useState('');

  const cancel = () => {
    setEditingKey('');
  };

  return (
    <>
      <div>
        {/* BlacklistDetail getApi={getApi} key={index} item={item} /> */}
        <AddBlacklistNew AddBlacklistGroup={AddBlacklistGroup} />
        {data &&
          data.map((item: any, index: any) => (
            <WhitelistDatail getApi={getApi} item={item} key={index} />
          ))}
      </div>
      <ApplyBlacklistNew
        getBlacklistApiOfStaff={getBlacklistApiOfStaff}
        whitelist={data}
        listUser={dataSource}
      />
      <div className="table-staff">
        <Form form={form} component={false}>
          <Table
            scroll={{ x: 600 }}
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            bordered
            dataSource={dataApply}
            columns={mergedColumns}
            rowClassName="editable-row"
            pagination={{
              onChange: cancel,
            }}
          />
        </Form>
      </div>
    </>
  );
}

function WhitelistDatail(props: any) {
  const [dataWhiteList, setDataWhiteList] = useState(originData);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingKey, setEditingKey] = useState('');
  useEffect(() => {
    convertArr();
  }, []);
  const convertArr = () => {
    let array = [];
    for (let i = 0; i < props.item.list.length; i++) {
      array.push({
        key: i,
        name: props.item.list[i],
      });
    }
    setDataWhiteList(array);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const isEditing = (record: any) => record.key === editingKey;

  const [form] = Form.useForm();
  const { Panel } = Collapse;
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleDelete = (record: any) => {
    let findArray = dataWhiteList.filter((item: any) => {
      return item.key !== record.key;
    });
    let updateData = findArray.map((item: any, index) => {
      item.key = index;
      return item;
    });

    let arrString: any = [];
    arrString = updateData.map((item: any) => item.name);

    setDataWhiteList(updateData);

    promotionClient
      .post('/web_rule/blacklist/admin/edit', {
        id: props.item.id,
        list: arrString,
      })
      .then((res: any) => {
        if (res.data.success) {
          notification.success({
            message: t('Success'),
            description: t('notification.whitelist.delete.success'),
          });
        } else {
          notification.error({
            message: 'Error',
            description: res.data.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err || 'error',
        });
      });
  };

  const EditableCell = (props: any) => {
    // const inputNode = props.inputType === 'number' ? <InputNumber /> : <Input />;
    const [errorMessage, setErrorMessage] = useState('');
    const combineDomainUrl = (eventChange: any) => {
      const value = eventChange.target.value;
      const domainRegex =
        /(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/;
      const match = value.match(domainRegex);
      if (match) {
        console.log('match', match);
        setErrorMessage('');
        props.data.forEach((element: any) => {
          if (element.name === value) {
            console.log('zzoooo');
            setErrorMessage(t('duplicate'));
            return;
          }
        });
      } else {
        console.log('no match', match);
        setErrorMessage(t('url.incorrect'));
      }
    };
    return (
      <td key={props.key} {...props}>
        {props.editing ? (
          <>
            <Form.Item
              name={props.dataIndex}
              style={{
                margin: 0,
              }}
              rules={[
                {
                  required: true,
                  message: `${t('notification.input.please', {
                    name: props.title,
                  })}`,
                },
              ]}
            >
              <Input onChange={combineDomainUrl} />
            </Form.Item>
            <span style={{ color: 'red' }}>{errorMessage}</span>
            {isMobile && (
              <div>
                <span>
                  <Button
                    type="primary"
                    ghost
                    onClick={() => save(props.record, 'edit')}
                    style={{
                      marginRight: '10px',
                    }}
                  >
                    {t('userManagement.blackList.createWebsite.btnSave')}
                  </Button>
                  <Button
                    onClick={cancel}
                    style={{
                      marginRight: '10px',
                      marginTop: '10px',
                    }}
                  >
                    {t('userManagement.blackList.createWebsite.btnCancel')}
                  </Button>
                </span>
              </div>
            )}
          </>
        ) : (
          props.children
        )}
      </td>
    );
  };
  const columns = [
    {
      title: `${t('userManagement.blackList.createWebsite.id')}`,
      dataIndex: 'id',
      key: 'id',
      width: 45,
      render: (value: any, staff: any, index: any) => <span>{index + 1}</span>,
    },
    {
      title: `${t('userManagement.blackList.createWebsite.name')}`,
      dataIndex: 'name',
      editable: true,
      render: (value: any, staff: any, index: any) => {
        const domainRegex =
          /(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})/;
        const match = value.match(domainRegex);
        const editable = isEditing(staff);
        return (
          <div>
            {value}
            {!match && <div style={{ color: 'red' }}>{t('url.incorrect')}</div>}
            {staff.isDuplicate && (
              <div style={{ color: 'red' }}>{t('duplicate')}</div>
            )}
            {isMobile && (
              <div>
                {editable ? (
                  <span>
                    <Button
                      type="primary"
                      ghost
                      onClick={() => save(staff, 'edit')}
                      style={{
                        marginRight: '10px',
                      }}
                    >
                      {t('userManagement.blackList.createWebsite.btnSave')}
                    </Button>
                    <Button
                      onClick={cancel}
                      style={{
                        marginRight: '10px',
                        marginTop: '10px',
                      }}
                    >
                      {t('userManagement.blackList.createWebsite.btnCancel')}
                    </Button>
                  </span>
                ) : (
                  <Button
                    disabled={editingKey !== ''}
                    onClick={() => edit(staff)}
                    style={{
                      marginRight: '10px',
                    }}
                  >
                    {t('userManagement.blackList.createWebsite.btnEdit')}
                  </Button>
                )}

                <Button
                  disabled={false}
                  style={{ marginTop: '10px' }}
                  onClick={() => handleDelete(staff)}
                  danger
                >
                  {t('userManagement.blackList.createWebsite.btnDelete')}
                </Button>
              </div>
            )}
          </div>
        );
      },
      sorter: (a: any, b: any) => a.name.length - b.name.length,
    },
    {
      title: `${t('userManagement.blackList.createWebsite.action')}`,
      dataIndex: 'operation',
      width: '25%',
      render: (value: any, record: { key: any }) => {
        const editable = isEditing(record);
        return (
          <div>
            {editable ? (
              <span>
                <Button
                  type="primary"
                  ghost
                  onClick={() => save(record, 'edit')}
                  style={{
                    marginRight: '10px',
                  }}
                >
                  {t('userManagement.blackList.createWebsite.btnSave')}
                </Button>
                <Button
                  onClick={cancel}
                  style={{
                    marginRight: '10px',
                    marginTop: '10px',
                  }}
                >
                  {t('userManagement.blackList.createWebsite.btnCancel')}
                </Button>
                {/* <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
                        <a>Cancel</a>
                      </Popconfirm> */}
              </span>
            ) : (
              <Button
                disabled={editingKey !== ''}
                onClick={() => edit(record)}
                style={{
                  marginRight: '10px',
                }}
              >
                {t('userManagement.blackList.createWebsite.btnEdit')}
              </Button>
            )}

            <Button
              disabled={false}
              style={{ marginTop: '10px' }}
              onClick={() => handleDelete(record)}
              danger
            >
              {t('userManagement.blackList.createWebsite.btnDelete')}
            </Button>
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col: any) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: any) => ({
        record,
        inputType: col.dataIndex === 'time' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        data: dataWhiteList,
      }),
    };
  });
  const EditBlackList = (value: any) => {
    promotionClient
      .post('/web_rule/blacklist/admin/set_name', {
        id: props.item.id,
        name: value.blacklistName,
      })
      .then((res: any) => {
        if (res.data.success) {
          notification.success({
            message: t('Success'),
            description: t('notification.update.success', {
              type: t('blacklistType'),
            }),
          });
          setIsModalVisible(false);
        } else {
          notification.error({
            message: t('Error'),
            description: res.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err || 'error',
        });
      });
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const isAdd = useRef(false);

  const save = async (record: any, isDelete: string) => {
    let arrString: any = [];
    try {
      const row = await form.validateFields();
      const newData: any = [...dataWhiteList];
      const index = newData.findIndex((item: any) => record.key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setDataWhiteList(newData);
        setEditingKey('');

        arrString = newData.map((item: any) => item.name);

        promotionClient
          .post('/web_rule/blacklist/admin/edit', {
            id: props.item.id,
            list: arrString,
          })
          .then((res: any) => {
            if (res.data.success) {
              props.getApi();
              notification.success({
                message: t('Success'),
                description: isAdd.current
                  ? t('notification.whitelist.addApp.success')
                  : t('notification.whitelist.updateApp.success'),
              });
            } else {
              notification.error({
                message: 'Error',
                description: res.data.message,
              });
            }
          })
          .catch((err: any) => {
            notification.error({
              message: t('Error'),
              description: err || 'error',
            });
          })
          .finally(() => {
            isAdd.current = false;
          });
      } else {
        newData.push(row);
        setDataWhiteList(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const cancel = () => {
    setEditingKey('');
  };

  const edit = (record: any) => {
    form.setFieldsValue({
      name: '',
      ...record,
    });
    setEditingKey(record.key);
  };
  const handleAdd = () => {
    isAdd.current = true;
    const newData = {
      key: dataWhiteList.length + 1 || 1,
      name: `example.com`,
    };
    let arr = [newData, ...dataWhiteList];
    setDataWhiteList(arr);
    edit(newData);
  };
  const handleDeleteUser = () => {
    promotionClient
      .post('/web_rule/blacklist/admin/delete', {
        id: props.item.id,
      })
      .then((res: any) => {
        if (res.data.success) {
          notification.success({
            message: t('Success'),
            description: 'Delete blacklist successfully',
          });
          props.getApi();
        } else {
          notification.error({
            message: t('Error'),
            description: res.message,
          });
        }
      })
      .catch((err: any) => {
        notification.error({
          message: t('Error'),
          description: err || 'error',
        });
      });
  };
  const mapData: any = (data: any) => {
    const output = data.map((item: any, index: any, arr: any) => {
      const isDuplicate = arr.some(
        (obj: any, i: any) => obj.name === item.name && i !== index
      );
      return { ...item, isDuplicate };
    });

    return output;
  };
  return (
    <>
      <div style={{ marginTop: '10px' }} className={'blacklist-detail-main'}>
        <div className={'blacklist-detail-main-ab'}>
          <Popconfirm
            placement="topRight"
            title={t('notification.delete.title', { type: t('blacklistType') })}
            onConfirm={() => handleDeleteUser()}
            okText={t('notification.delete.yes')}
            cancelText={t('notification.delete.no')}
          >
            <DeleteOutlined style={{ color: 'red', fontSize: 25 }} />
          </Popconfirm>
        </div>
        <div className={'blacklist-detail-edit'}>
          <EditOutlined
            onClick={() => showModal()}
            style={{ fontSize: '150%' }}
          />
        </div>
        <Collapse
          defaultActiveKey={'0'}
          accordion
          expandIcon={({ isActive }) => (
            <ChromeOutlined
              twoToneColor="#52c41a"
              style={{ fontSize: '120%' }}
              rotate={isActive ? 130 : 0}
            />
          )}
        >
          <Panel header={props.item.name} key="1">
            <Form form={form} className={'mt-3'} component={false}>
              <Button
                onClick={handleAdd}
                type="primary"
                style={{
                  marginBottom: 16,
                }}
              >
                {t('userManagement.blackList.createWebsite.title')}
              </Button>
              <Table
                scroll={{ x: 600 }}
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                bordered
                dataSource={mapData(dataWhiteList)}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={{
                  onChange: cancel,
                }}
              />
            </Form>
          </Panel>
        </Collapse>

        <Modal
          title={t('userManagement.blackList.update.name')}
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
        >
          <Form
            form={form}
            name="basic"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            initialValues={props.item.name}
            onFinish={(value: any) => EditBlackList(value)}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label={t('userManagement.blackList.update.label')}
              name="blacklistName"
              rules={[
                {
                  required: true,
                  message: t('notification.input.blacklist.nameEdit', {
                    name: 'blacklist',
                  }),
                },
              ]}
            >
              <Input value={props.item.name} />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 6, span: 18 }}>
              <Button type="primary" className="mt-1" htmlType="submit">
                {t('userManagement.blackList.update.btnUpdate')}
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      </div>
    </>
  );
}
